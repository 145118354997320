import styled from 'styled-components'

export const Title = styled.h2`
  margin: 15px 0;
  font-size: 2.5em;
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  color: ${props => props.fontColor};
`

export const BigTitle = styled.h1`
  font-size: 3em;
  color: ${props => props.fontColor};
`

export const Subtitle = styled.p`
  font-size: 1.5em;
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  color: ${props => props.fontColor};
`
