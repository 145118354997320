import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Overlay from './Overlay'

const Wrapper = styled.a`
width: 100%;
box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
          0 5px 15px 0 rgba(0,0,0,0.08);
border-radius: 10px;
text-decoration: none;
color: #fff;
overflow: hidden;
position: relative;
background: ${props => props.bgOverlay};
background-repeat: no-repeat;
background-size: cover;
background-position: center top;
position: relative;
transition: all .5s;

@media screen and (min-width: 600px) {
  &:hover {
    cursor: pointer;
    transform: scale(1.05) translateY(-5px);

    h2 {
      color: #000;
    }
  }
}

@media (hover: none) {
    transform: scale(1) translateY(0);
}

@media all and (-ms-high-contrast:none) {
  width: 100%;
  margin: 5px;
}
`

const ProjectHeader = styled.div`
width: 100%;
margin: 0 auto;
height: 145px; 
position: relative;
overflow: hidden;  
background-size: cover;
background-position: center;
background-image: url(${props => props.bgImage});
background-repeat: no-repeat;

&:after {
  content: '';
  width: 145%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(-20deg) scale(1);
}
`

const ProjectContent = styled.div`
background: #fff;
padding: 25px 20px;
color: #413E46;
text-shadow: none;
height: 100%;

p {
  color: #BAB9BE;
  font-size: .75em;
  text-align: left;
  margin: 0 0 0 0;
}
`

const Text = styled.div`
font-size: .9em;
margin: 0 0 5px 0;
`

const Title = styled.div`
font-family: 'ubuntu', Helvetica, Arial, sans-serif;
font-size: 1.25em;
font-weight: 700;
margin-bottom: 5px;
`

const ProjectCard = ({ title, link, children, bgOverlay, bgImage, techList }) => (
  <Wrapper data-sal="slide-up" data-sal-easing="ease-in" href={link} rel="noopener noreferrer">
    <ProjectHeader bgImage={bgImage}>
    <Overlay bg={bgOverlay} opacityVal=".75" display="block" />
    </ProjectHeader >
    <ProjectContent>
      <Title>{title}</Title>
      <Text>{children}</Text>
      <p>{techList.join(', ')}</p>
    </ProjectContent>
  </Wrapper>
)

export default ProjectCard

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  bgOverlay: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  techList: PropTypes.array.isRequired
}
