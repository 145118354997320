import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { Title } from '../elements/Titles'

const ClientsContainer = styled.section`
    padding: 75px 25px 85px;

    ul {
      width: 100%;
      margin: 0 auto;
      padding: 25px 65px;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-template-rows: auto;
      grid-gap: 20px;
      justify-items: center;
      
      li {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 255px;
        text-align: center;
        background: #fff;
        padding: 25px 15px;
        box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
        0 5px 15px 0 rgba(0,0,0,0.08);
        border-radius: 10px;
        position: relative;

        img {
          width: 100%;
          margin: 0 auto;
        }
      }

      @media all and (-ms-high-contrast:none) {
        display: flex;
        flex-wrap: wrap;

        li {
          min-width: 180px;
          margin: 5px;
        }
      }
                    
      @media screen and (max-width: 987px) {
        padding: 15px;
        grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));

        li {
          width: 175px;
          padding: 15px 10px;
        }
      }

      @media screen and (max-width: 600px) {
        padding: 0;

        li {
          width: 125px;
          padding: 12px 7px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      padding: 45px 20px 0;
      grid-column-gap: 10px;
      grid-row-gap: 0px;

      ul {
        grid-template-columns: repeat(auto-fit,minmax(135px,1fr));
      }
    }
`

const Paragraph = styled.p`
    text-align: center;
`

const Clients = ({ clientData }) => (
  <>
    <ClientsContainer>
        <div data-sal="fade" data-sal-easing="ease-in">
          <Title fontColor="#413E46" textAlign="center">Clients</Title>
          <Paragraph>I have worked alongside some companies and teams small to large. Working on projects of all shapes and sizes.</Paragraph>
        </div>
        
        <ul>
          { clientData.edges.map( client => <li key={client.node.id} data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease-in-bounce"><LazyLoadImage src={client.node.logo.file.url} effect="blur" alt={client.node.altText} /></li> ) }
        </ul>
    </ClientsContainer>
  </>
)

export default Clients

Clients.propTypes = {
  clientData: PropTypes.object.isRequired
}
