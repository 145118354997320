import React from 'react'
import PropTypes from 'prop-types'
    
const getRandomPoint = (maxDim) => Math.floor(Math.random() * maxDim)
    
const generatePath = (numberOfPoints) => {
    const pathWidth = 400
    const pathHeight = 400
    let pathCoords = 'M1 2 '
    const isCurvy = true

    if (!isCurvy) {
        // assign full list of points
        for (let i = 0; i < numberOfPoints; i += 1) {
            pathCoords += (`L${getRandomPoint(pathWidth)} ${getRandomPoint(pathHeight)}`)
        }
    } else {
        for (let i = 0; i < 5; i += 1) {
            pathCoords += ('C ')
            for (let j = 0; j < 3; j += 1) {
                pathCoords += (`${getRandomPoint(pathWidth)} ${getRandomPoint(pathHeight)} `)
            }
        }   
    }
    pathCoords += 'Z'
    return pathCoords;
}

const generateBlob = ({ points, color }) => {
    // create path
    const numberOfPoints = points || Math.floor(Math.random() * 12) + 3
    const pathCoords = generatePath(numberOfPoints)
    
    // return the SVG
    return (
        <>
            <svg><path d={pathCoords} style={{fill: color, stroke: 'none'}} /></svg>
        </>
    );
}

export default generateBlob

generateBlob.propTypes = {
    points: PropTypes.node.isRequired,
    color: PropTypes.node.isRequired
}
