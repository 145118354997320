import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ProjectSection = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 75px 55px 125px;

  @media screen and (max-width: 600px) {
    padding: 45px 20px 75px;
  }
`

const Projects = ({ children }) => (
  <>
    <ProjectSection>
      {children}
    </ProjectSection>
  </>
)

export default Projects

Projects.propTypes = {
  children: PropTypes.node.isRequired
}
