import React from 'react'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Title } from '../elements/Titles'
import 'react-lazy-load-image-component/src/effects/blur.css'
import avatar from '../../static/images/about-photo.png'

const AboutContainer = styled.section`
  background: linear-gradient(to right bottom,rgb(29, 28, 35) 35%,rgb(76, 60, 113) 100%);
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(8, auto);
  grid-auto-rows: auto;
  align-items: flex-start;
  justify-items: center;
  padding: 100px 55px;
  border-radius: 400% 100% 400% 100% / 20%;
  position: relative;
  overflow: hidden;
  z-index: 400;

  @media screen and (max-width: 835px) {
    display: block;
  }
`

const AboutHero = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 45px;
  
  @media screen and (max-width: 835px) {
    max-width: 350px;
  }
`

const Avatar = styled(LazyLoadImage)`
  width: 100%;
  max-width: 350px;
`

const AboutTitle = styled(Title)`
  padding-bottom: 30px;
  margin-bottom: 35px;
  position: relative;
  line-height: 1.2em;

  &:after {
      content: '';
      display: block;
      width: 105px;
      height: 4px;
      background-color: #f9e15e;
      position: absolute;
      bottom: 0;
  }
`

const AboutDesc = styled.div`
  font-size: 1em;
  line-height: 1.7em;
  color: #fff;
`

const About = () => (
  <>
    <AboutContainer>
      <AboutHero data-sal="slide-right" data-sal-easing="ease-in">
        <Avatar src={avatar} alt="Raymond Ware" effect="blur" />
      </AboutHero>
      <AboutDesc data-sal="slide-left" data-sal-easing="ease-in">
        <AboutTitle fontColor="#fff">Get to know me</AboutTitle>
        <p>I am a Full Stack Developer &amp; Designer with a passion for creating beautiful user interfaces. I have 10 years of experience in web development &amp; design, gaining many skills along the way.</p>

<p>I am highly proficient with the core front end technologies (HTML, CSS JavaScript), as well as the backend with PHP and NodeJS. I find myself working a lot with ReactJS, VueJS, WordPress, and Woocommerce these days.</p>

<p>Outside of work I spend my time learning new technology, hanging with my family, reading, and playing golf.</p>
      </AboutDesc>
    </AboutContainer>
  </>
)

export default About
