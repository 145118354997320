import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Elements
import { Button } from '../styles/buttons'
import { Title } from '../elements/Titles'
import Blob from '../components/Blob'

let tempBackgroundColor = ['#ffffff', '#000000']

const rgbToHex = function (rgb) { 
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
            hex = `0${  hex}`;
    }
    return hex;
}

const getHexBetween = (arr) => {
    const hex1 = arr[0].replace('#', '')
    const hex2 = arr[1].replace('#', '')

    const r = parseInt(hex1.substr(0,2),16);
    const g = parseInt(hex1.substr(2,2),16);
    const b = parseInt(hex1.substr(4,2),16);

    const r2 = parseInt(hex2.substr(0,2),16);
    const g2 = parseInt(hex2.substr(2,2),16);
    const b2 = parseInt(hex2.substr(4,2),16);

    const avg = [Math.ceil((r+r2)/2), Math.ceil((g+g2)/2), Math.ceil((b+b2)/2)]

    const [red, green, blue] = [rgbToHex(avg[0]), rgbToHex(avg[1]), rgbToHex(avg[2])]
    const fullHex = `#${red}${green}${blue}`

    return fullHex
}

const getContrast = hex => {
    const hexColor = hex.replace('#', '');
    const r = parseInt(hexColor.substr(0,2),16);
    const g = parseInt(hexColor.substr(2,2),16);
    const b = parseInt(hexColor.substr(4,2),16);
    const yiq = ((r*299)+(g*587)+(b*114))/1000;
    
    return (yiq >= 190) ? 'black' : 'white';
}

const generateRandomTheme = () => {
    const adrift = ['#CFF09E', '#A8DBA8', '#79BD9A', '#3B8686', '#0B486B']
    const terra = ['#E8DDCB', '#CDB380', '#036564', '#033649', '#364f75']
    const pancake = ['#594F4F', '#547980', '#45ADA8', '#9DE0AD', '#E5FCC2']
    const fire = ['#e36868', '#f5af5f', '#C7F464', '#FF6B6B', '#eddb74']
    const waves = ['#D3E2B6', '#C3DBB4', '#AACCB1', '#87BDB1', '#68B3AF']
    const story = ['#F8B195', '#F67280', '#C06C84', '#6C5B7B', '#355C7D']
    const ice = ['#F7F9FE', '#ECF1F2', '#DCE8Ee', '#CBDBE0', '#BED2D9']
    const winter = ['#AAB3AB', '#C4CBB7', '#EBEFC9', '#EEE0B7', '#E8CAAF']
    const rings = ['#EFF3CD', '#B2D5BA', '#61ADA0', '#248F8D', '#56bfbf']
    const brightSide = ['#8827f3','#a677fd','#f279e7','#66b9fd','#637df8']

    const colors = [adrift, terra, pancake, fire, waves, story, ice, winter, rings, brightSide]

    const randomTheme = colors[Math.floor(Math.random() * colors.length)]
    return randomTheme
}

const randomBackgroundColor = (theme) => {
    const randomColor = theme[Math.floor(Math.random() * theme.length)]
    const randomColor2 = theme[Math.floor(Math.random() * theme.length)]

    return [`linear-gradient(-45deg, ${randomColor}, ${randomColor2})`, randomColor, randomColor2]
}

const ServicesSection = styled.section`
    background: linear-gradient(to right bottom,rgb(29, 28, 35) 35%,rgb(76, 60, 113) 100%);
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(8, auto);
    grid-auto-rows: auto;
    align-items: flex-start;
    justify-items: center;
    padding: 155px 55px;
    border-radius: 400% 100% 400% 100% / 20%;
    position: relative;
    overflow: hidden;

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        opacity: .15;
        background-blend-mode: multiply;
        z-index: -1;
        
        path {
            transform: scale(10) rotate(5deg);
        }
    }

    @media screen and (max-width: 700px) {
        display: block;
    }

    @media screen and (max-width: 960px) {
        background: rgb(32, 21, 74);
        padding: 105px 20px 100px 20px;
        border-radius: 50% / 5%;
    }

    @media screen and (max-width: 890px) {
        grid-template-columns: 1fr;
    }
`

const ServicesContainer = styled.div`
    width: 100%;
    min-width: 550px;
    max-width: 750px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    grid-column-start: span 4;

    .container {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 20px;
    
        &:last-child {
        margin-right: 0;
        }
    
        &-1, &-3 {
        margin-top: 75px;
        }
    
        @media screen and (max-width: 700px) {
            width: 100%;
            display: grid;
            grid-gap: 15px;
            margin: 0 0 15px 0;
        
            &-3 {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 700px) {
        grid-column-start: span 1;
        display: block;
        min-width: auto;
    }
`

const ServicesDescription = styled.div`
    max-width: 350px;
    grid-column-start: span 4;
    color: #fff;

    p {
        font-size: 1.125em;
        line-height: 1.4em;
    }
`

const ServicesTitle = styled(Title)`
padding-bottom: 30px;
margin-bottom: 35px;
position: relative;

&:after {
    content: '';
    display: block;
    width: 105px;
    height: 4px;
    background-color: #f9e15e;
    position: absolute;
    bottom: 0;
}
`

const Elm = styled.div`
    min-height: 150px;
    background: ${props => {
        const randomColor = randomBackgroundColor(props.theme)
        tempBackgroundColor = [randomColor[1], randomColor[2]]
        return randomColor[0]
    }};
    padding: 25px;
    border-radius: 7px;
    margin-bottom: 20px;
    overflow: hidden;
    position:relative;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transition: 1s all;
    cursor: pointer;
    transition: all .5s;

    @media screen and (min-width: 600px) {
        &:hover {
            transform: scale(1.05) translateY(-5px);
        }
    }

    @media (hover: none) {
        transform: scale(1) translateY(0);
    }

    img {
        width: 150%;
        position: absolute;
        top: -45px;
        left: 0;
        margin: auto auto;
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .07;
        transition: 2s all;
        z-index: 1;
        mix-blend-mode: overlay;

        &:hover {

            path {
                opacity: 0;
                transform: scale(2) rotate(2deg) translate(0px, -15px);
            }
        }

        path {
            transition: 2s all;
            transform: scale(1.1) rotate(0deg);
        }
    }

    h4 {
        color: ${() => {
            const fontColor = getContrast(getHexBetween(tempBackgroundColor))
            return fontColor
        }};
    }

    p {
        color: ${() => {
            const fontColor = getContrast(getHexBetween(tempBackgroundColor))
            return fontColor
        }};
        font-size: 1em;
        line-height: 1.4em;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        margin: 0;
        padding: 15px;
    }
`

const IconWrap = styled.div`
    width: 55px;
    height: 55px;
    position: absolute;
    z-index: 2222;
    display: flex;
    margin: auto;
    align-items: center;
    justify-items: center;
    background-color: rgba(255,255,255,0.75);
    border-radius: 50%;
    font-size: 36px;
    z-index: 1;

    svg {
        opacity: 1;
        margin: auto;
        mix-blend-mode: inherit;
        z-index: -1;

        path {
            transform: scale(1);
            z-index: -1;
        }

        &:hover {

            path {
                opacity: 1;
                transform: none;
            }
        }
    }
`

const ServicesItemText = styled.h4`
    color: #0c0c0c;
    font-size: 1.2em;
    font-weight: bold;
    margin: 75px 0 10px 0;

    @media screen and (max-width: 600px) {
        font-size: 1em;
    }
`

const ColorPicker = styled.div`
    z-index: 4444;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .colorpick-eyedropper-input-trigger{
        display: none;
    }

    input[type="color"] {
        -webkit-appearance: none;
        border: none;
        width: 100%;
        height: 35;
        padding: 0;
        cursor: pointer;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
        border: none;
    }
    input[type="color"]::-webkit-color-swatch {
        border: none;
        padding: 0;
    }
`

const SendTheme = styled.div`
    margin: 25px 0;
    transition: all .5s;
`

const generateRandomNumber = () => Math.floor(Math.random() * 65)

const ServiceCard = ({ icon, serviceText, description, theme }) => (<>
    <Elm theme={theme}><Blob points={generateRandomNumber} color="#fff" />
        <IconWrap>
            <FontAwesomeIcon icon={[icon[0].prefix, icon[0].icon]} color="#352e47" />
        </IconWrap>
        <ServicesItemText>
            { serviceText }
        </ServicesItemText>
        <p>{ description }</p>
    </Elm>
</>)

const Services = ({ serviceData}) => {
    const [data, setData] = useState([])
    const [dataLength, setLength] = useState(0)
    const [currentTheme, setCurrentTheme] = useState([])
    const [userCustomTheme, setUserCustomTheme] = useState(false)

    useEffect(() => {
        const randomTheme = generateRandomTheme()
        setCurrentTheme(randomTheme)
    }, [])

    useEffect(() => {
        const chunkedData = serviceData.edges.length >= 3 ? _.chunk(serviceData.edges, Math.floor(serviceData.edges.length / 3)) : serviceData.edges

        const handleDataChange = () => {
            setData(chunkedData)
            setLength(serviceData.edges.length)
        }

        return handleDataChange()        
    }, [serviceData.edges])

    const changeTheme = () => {
        const randomTheme = generateRandomTheme()
        setCurrentTheme(randomTheme)
    }

    const createTheme = (e) => {
        const newValue = e.target.value
        const {key} = e.target.dataset
        const theme = JSON.parse(JSON.stringify(currentTheme))
        theme[key] = newValue
        setCurrentTheme(theme)
        setUserCustomTheme(true)
    }

    return(
  <>
    <ServicesSection id="services">
        <Blob points={generateRandomNumber} color="#000" />
        <ServicesDescription data-sal="fade" data-sal-delay="100" data-sal-easing="ease-in">
            <ServicesTitle fontColor="#fff" onClick={changeTheme}>Is there something<br />I can help with?</ServicesTitle>
            <p>I have a wide array of experience and may benefit you or your team. Here are some of the services I offer.</p>
            <ColorPicker>
                <input type="color" name="theme1" data-key="0" value={currentTheme[0]} onChange={createTheme} />
                <input type="color" name="theme2" data-key="1" value={currentTheme[1]} onChange={createTheme} />
                <input type="color" name="theme3" data-key="2" value={currentTheme[2]} onChange={createTheme} />
                <input type="color" name="theme4" data-key="3" value={currentTheme[3]} onChange={createTheme} />
                <input type="color" name="theme5" data-key="4" value={currentTheme[4]} onChange={createTheme} />
            </ColorPicker>
        </ServicesDescription>

        <ServicesContainer data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease-in">
            { dataLength >= 3 ? <><div className="container container-1">
                {data[0].map((item) => <ServiceCard key={item.node.id} theme={currentTheme} icon={item.node.icon} serviceText={item.node.title} description={item.node.description ? item.node.description.description : ''} />)}
            </div>
            <div className="container container-2">
            {data[1].map((item) => <ServiceCard key={item.node.id} theme={currentTheme} icon={item.node.icon} serviceText={item.node.title} description={item.node.description ? item.node.description.description : ''} />)}
            </div>
            <div className="container container-3">
            {data[2].map((item) => <ServiceCard key={item.node.id} theme={currentTheme} icon={item.node.icon} serviceText={item.node.title} description={item.node.description ? item.node.description.description : ''} />)}
            </div></> : (data.map((item) => <ServiceCard key={item.node.id} theme={currentTheme} icon={item.node.icon} serviceText={item.node.title} description={item.node.description ? item.node.description.description : ''} />))}
        </ServicesContainer>
    </ServicesSection>
  </>
)}

export default Services

Services.propTypes = {
    serviceData: PropTypes.node.isRequired
}

ServiceCard.propTypes = {
    icon: PropTypes.array.isRequired,
    serviceText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    theme:PropTypes.array.isRequired
}
