import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { UpDown } from '../styles/animations'
import 'react-lazy-load-image-component/src/effects/blur.css';

import ContactForm from '../components/ContactForm';

const ContactSection = styled.div`
  position: relative;
  height: 1000px;
  padding: 75px 25px 135px;
  z-index: 250;
  display: flex;
  justify-content: flex-end;
  
  @media screen and (max-width: 650px) {
    display: block;
    padding: 45px 15px 65px;
    height: auto;
  }

  &:after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      min-height: 900px;
      padding: 75px 25px 135px;
      background-image: url(/images/contact-background.png);
      background-repeat: no-repeat;
      top: -145px;
      left: -50px;
      z-index: 255;
      height: 1145px;
      width: 100%;
      clip-path: inset(0 0 0 50px);
  
      @media screen and (max-width: 1200px) {
        left: -200px;
      }
  
      @media screen and (max-width: 1100px) {
        display: none;
      }
    }
`

const ContactImage = styled(UpDown)`
  z-index: 9999;
  position: absolute;
  width: 100%;
  max-width: 380px;
  left: 180px;
  bottom: 140px;
  align-self: center;

  .contact-image {
    width: 350px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    left: 70px;
    bottom: 70px;
  }

  @media screen and (max-width: 900px) {
    max-width: 250px;
    
    .contact-image {
      width: 270px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const ContactItemsWrap = styled.div`
  width: 50%;
  align-self: center;
  justify-self: flex-end;
  z-index: 4444;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: auto;
  justify-items: flex-start;
  grid-gap: 25px;
  z-index: 9999;

  @media screen and (max-width: 1100px) {
    width: 100%;
    grid-template-columns: 100%;
    justify-items: center;
    margin: 0 auto;
  }
`

const ContactItem = styled.a`
  width: 100%;
  color: #413E46;
  display: flex;
  grid-template-columns: auto 75px;
  align-items: center;
  padding: 15px 0 15px 25px;
  background: #fff;
  box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
          0 5px 15px 0 rgba(0,0,0,0.08);
  border-radius: 10px;
  border: 2px solid #c0aee8;
  cursor: pointer;
  transition: all .7s;

  &:hover {
    background: linear-gradient(to bottom right, #3B2085, #5229B7);
    box-shadow: 0 15px 30px 0 rgba(70,33,171,0.41), 0 5px 15px 0 rgba(64,37,138,0.28);
    color: #fff;
    transform: scale(1.07);
    border: 2px solid #160C28;
    
    svg {
      color: #2b1569;
    }
  }
`

const ContactInfo = styled.div`
  margin-right: 25px; 

  h4 {
    margin: 0;
    font-size: 1.4em;
  }

  p {
    margin: 0;
    font-size: .9em;
  }
`

const IconWrap = styled.div`
  margin: 0 25px 0 auto;
  font-size: 3em;
`

const Contact = ({serviceData}) => {
  return (
    <ContactSection id="contact_form">
      
      <ContactImage data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease-in">
        <LazyLoadImage className="contact-image" src="/images/ui-mockup.svg" alt="UI Development" effect="blur" />
      </ContactImage>
      <ContactItemsWrap>
      
        <ContactForm serviceData={serviceData} />
        
        <ContactItem href="https://github.com/raymondware" target="_blank">
          <ContactInfo>
            <h4>Github</h4>
            <p>Check out some of my code.</p>
          </ContactInfo>
          <IconWrap>
            <FontAwesomeIcon icon={['fab', 'github']} color="#b0afd4" />
          </IconWrap>
        </ContactItem>

        <ContactItem href="https://www.linkedin.com/in/raymondware/" target="_blank">
          <ContactInfo>
            <h4>LinkedIn</h4>
            <p>View my professional background.</p>
          </ContactInfo>
          <IconWrap>
            <FontAwesomeIcon icon={['fab', 'linkedin']} color="#b0afd4" />
          </IconWrap>
        </ContactItem>
      </ContactItemsWrap>
    </ContactSection>
  )
}

Contact.propTypes = {
  serviceData: PropTypes.node.isRequired
}

export default Contact
