import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Particles from 'react-particles-js'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCube, faUsers, faShoppingCart, faCreditCard, faObjectUngroup, faTimesCircle, faCoffee, faHandSpock, faAllergies, faCubes, faIdCard, faDatabase, faDiagnoses, faObjectGroup, faPlug, faComments, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faWordpress, faReact, faVuejs, faCcPaypal, faCcStripe, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

// Animations
import { UpDown } from '../styles/animations'

// Components
import Layout from '../layouts/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import { Title } from '../elements/Titles'

// Sections
import Hero from '../sections/Hero'
import Projects from '../sections/Projects'
import Services from '../sections/Services'
import Clients from '../sections/Clients'
import About from '../sections/About'
import Contact from '../sections/Contact'
import Footer from '../sections/Footer'

// Hero components
import Overlay from '../components/Overlay'
import { Button } from '../styles/buttons'

// Add icons to library
library.add(faCheckSquare, faCube, faUsers, faTimesCircle, faIdCard, faObjectUngroup, faCreditCard,
    faComments, faCoffee, faHandSpock, faAllergies, faWordpress, 
    faReact, faVuejs, faCcPaypal, faCcStripe, faCubes, faDatabase, 
    faDiagnoses, faGithub, faObjectGroup, faPlug, faLinkedin,
  faShoppingCart, faCheck)

export const assetQuery = graphql` query { 
  allContentfulProjects {
    edges {
      node {
        id
        slug
        technologyUsed
        title
        description
        thumbnailImage {
          file {
            url
          }
          title
        }
      }
    }
  },
  allContentfulServices {
    edges {
      node {
        title
        slug
        id
        description {
          description
        }
        icon {
          icon
          prefix
        }
      }
    }
  },
  allContentfulClients {
    edges {
      node {
        id
        url
        logo {
          file {
            url
          }
        }
        altText
      }
    }
  }
}`

const ProjectsWrapper = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 70px;

  @media screen and (max-width: 1200px) {
    grid-gap: 20px;
  }

  @media screen and (max-width: 1160px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 840px) {
    grid-gap: 25px;
    margin-top: 35px;
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: 1fr;
  }

  @media all and (-ms-high-contrast:none) {
    display: flex;
    flex-wrap: wrap;
  }
`

const HeroContentContainer = styled.div`
  width: 45%;
  color: #fff;

  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: center;
    z-index: 9999;
  }
`

const HeroSubtitle = styled.h4`
  font-size: 2em;
  color: #fff;
  margin: 0;
  padding-bottom: 20px;
  position: relative;

  @media screen and (max-width: 960px) {
    font-size: 1.5em;
  }

  &:after {
    content: '';
    display: block;
    width: 105px;
    height: 4px;
    background-color: #f9e15e;
    position: absolute;
    bottom: 0;

    @media screen and (max-width: 960px) {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`

const HeroTitle = styled.h1`
  font-size: 2.7em;
  margin: 15px 0 25px;
  margin-bottom: 25px;

  @media screen and (max-width: 960px) {
    font-size: 2.5em;
  }
`

const HeroText = styled.p`
  color: #fff;
  font-size: 1.125em;
  line-height: 1.5em;
  color: #B6AFD1;
  max-width: 450px;

  @media screen and (max-width: 960px) {
    font-size: 1.1em;
    line-height: 1.4em;
    color: #fff;
    margin: 15px auto 25px;
  }
`

const HeroUiContainer = styled(UpDown)`
  z-index: 100;
  position: relative;
  top: 0;
  right: -70px;

  @media screen and (max-width: 1260px) {
    top: -20px;
    right: -75px;
  }

  @media screen and (max-width: 960px) {
    width: 70%;
    max-width: 500px;
    align-items: center;
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: .4;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    max-width: 400px;
  }

  img {
    width: 500px;

    @media screen and (max-width: 1260px) {
      width: 450px;
    }

    @media screen and (max-width: 1025px) {
      width: 400px;
    }
  }
`

const ServerMagic = styled.span`
    width: 100%;
    position: absolute;
    top: -65px;
    left: -135px;
    z-index: 60;

    canvas {
        display: block;
        vertical-align: bottom;
    }
`

const particleParams = {
  'particles': {
      'number': {
          'value': 700,
          'density': {
              'enable': true,
              'value_area': 300
          }
      },
      'color': {
          'value': '#ffffff'
      },
      'shape': {
          'type': 'circle',
          'stroke': {
              'width': 0,
              'color': '#000000'
          },
          'polygon': {
              'nb_sides': 5
          },
          'image': {
              'src': 'img/github.svg',
              'width': 100,
              'height': 100
          }
      },
      'opacity': {
          'value': 0.48927153781200905,
          'random': true,
          'anim': {
              'enable': true,
              'speed': 0.2,
              'opacity_min': 0,
              'sync': false
          }
      },
      'size': {
          'value': 3,
          'random': true,
          'anim': {
              'enable': true,
              'speed': 2,
              'size_min': 0,
              'sync': false
          }
      },
      'line_linked': {
          'enable': false,
          'distance': 150,
          'color': '#ffffff',
          'opacity': 0.4,
          'width': 1
      },
      'move': {
          'enable': true,
          'speed': 0.2,
          'direction': 'top',
          'random': false,
          'straight': false,
          'out_mode': 'out',
          'bounce': false,
          'attract': {
              'enable': false,
              'rotateX': 600,
              'rotateY': 1200
          }
      }
      },
      'interactivity': {
      'detect_on': 'canvas',
      'events': {
          'onhover': {
              'enable': false,
              'mode': 'push'
          },
          'onclick': {
              'enable': false,
              'mode': 'push'
          },
          'resize': true
      },
      'modes': {
          'grab': {
              'distance': 800,
                  'line_linked': {
                      'opacity': 1
                  }
              },
              'bubble': {
              'distance': 83.91608391608392,
              'size': 1,
              'duration': 3,
              'opacity': 1,
              'speed': 3
          },
          'repulse': {
              'distance': 200,
              'duration': 0.4
          },
          'push': {
              'particles_nb': 4
          },
          'remove': {
              'particles_nb': 2
          }
      }
  },
  'retina_detect': true
}

const ProjectSectionDescription = styled.p`
  margin: 0 auto 65px;
  text-align: center;
  max-width: 750px;
`

const Wrapper= styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  background: url('/images/wood-bg.jpg');
  box-shadow: 0 12px 30px 0 rgba(0,0,0,0.11),
    0 5px 15px 0 rgba(0,0,0,0.08);
`

const Index = ({ data }) => (
  <>
  <Layout siteTitle="Raymond Ware :: Fullstack VueJS and ReactJS Developer" siteDescription="Freelance full stack web developer specializing in Reactjs, vuejs, NodeJS, PHP, WordPress, and Woocommerce." />
  <Wrapper id="main-wrapper">
      <Hero>
        <Overlay bg="linear-gradient(to bottom, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,0) 100%);" opacityVal=".98" display="none" mediaDisplay="960px" />
        <HeroContentContainer>
          <HeroSubtitle>
            Full Stack Web Developer
          </HeroSubtitle>
          <HeroTitle>Working on the Cutting Edge</HeroTitle>
          <HeroText>
            I have over 14 years of experience doing a little bit of everything. From UI design, frontend development, and backend development. I am a modern developer that stays up to date with the latest technologies and tools.
          </HeroText>
          <HeroText>I have an eye for design and enjoy creating simple and intuitive user experiences. Let's create something great!</HeroText>

          <AnchorLink href="#services"><Button rightMargin="25px" primary>Services</Button></AnchorLink>
          <AnchorLink href="#contact_form"><Button outlined>Contact</Button></AnchorLink>
        </HeroContentContainer>

        <HeroUiContainer>
          <ServerMagic>
            <Particles 
                style={{margin: '0 auto'}}
                params={particleParams}
                width="60px"
                height="150px" />
          </ServerMagic>
          <img src="/images/raymondware-ui-development.svg" alt="Raymond Ware UI Development" />
        </HeroUiContainer>
      </Hero>

      <Projects id="projects">
        <div data-sal="fade" data-sal-delay="100" data-sal-easing="ease-in">
        <Title fontColor="#413E46" textAlign="center">Projects I have built</Title>
        <ProjectSectionDescription>I have a lot of experience building websites and web apps. Here are a few projects that I have worked on over the years. Dive deeper into each project if you are curious about the role I played in the project, as well as the software/technology used. Some of my most recent projects are here, but I have yet to update this section with some of my older work.</ProjectSectionDescription>
        </div>
        <ProjectsWrapper>

          {data.allContentfulProjects.edges.map( project =>
            <LazyLoadComponent>
              <ProjectCard
                title={project.node.title}
                link={`/projects/${project.node.slug}`}
                bgOverlay="linear-gradient(to bottom right, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,1) 100%);"
                bgImage={project.node.thumbnailImage.file.url}
                techList={project.node.technologyUsed}
                key={project.id}
              >
                {project.node.description}
              </ProjectCard>
            </LazyLoadComponent>
           )}

        </ProjectsWrapper>
      </Projects>

      <Services serviceData={data.allContentfulServices} />

      <Clients clientData={data.allContentfulClients} />

      <About />
      <Contact serviceData={data.allContentfulServices} />
      <Footer />
  </Wrapper>
</>
)

export default Index

Index.propTypes = {
  data: PropTypes.object
}

Index.defaultProps = {
  data: {}
};
