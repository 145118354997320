import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NavBar from '../components/NavBar'

const HeroSection = styled.div`
  background: linear-gradient(to right bottom, rgb(32, 21, 74) 35%, rgb(89, 44, 197) 100%);
  width: 100%;
  margin: 0 auto;
  padding: 25px 55px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 0 0 400% 100% / 100%;

  @media screen and (max-width: 960px) {
    background: rgb(32, 21, 74);
    padding: 25px 25px 70px 25px;
    border-radius: 0 0 50% 50% / 5%;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    background-image: url(../images/table-top.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right 70px;
    width: 75%;
    height: 100%;
    right: -215px;
    bottom: 0;

    @media screen and (max-width: 1260px) {
      width: 65%;
      background-position: right 70px;
      right: -135px;
    }

    @media screen and (max-width: 1050px) {
      width: 64%;
      background-position: right 80px;
      right: -145px;
    }

    @media screen and (max-width: 960px) {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 55px 0 65px 0;

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  @media screen and (max-width: 500px) {
    align-items: center;
    justify-content: center;
  }
`

const Hero = ({ children }) => (
  <>
    <HeroSection>
        <NavBar />
        <Wrapper>{children}</Wrapper>
    </HeroSection>
  </>
)

export default Hero

Hero.propTypes = {
  children: PropTypes.node.isRequired
}
